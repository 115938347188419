<div class="adv-block-container">

    <div class="foot-ban" id="footer-nat">
        <div class="footer-banners-container">
            <div class="footer-banner">
                <div id="footer_1"></div>
            </div>
            <div class="footer-banner">
                <div id="footer_2"></div>
            </div>
            <div class="footer-banner">
                <div id="footer_3"></div>
            </div>
            <div class="footer-banner">
                <div id="footer_4"></div>
            </div>
        </div>
    </div>

</div>
<div class="footer cf">
    <div class="wrapper">

        <div class="footer-content">

            <div class="footer-item wide">
                <div class="footer-copyright">
                    <p class="copyright">
                        <span class="text-bold text-black">Disclaimer:</span> xxxbulk has a zero-tolerance policy
                        against illegal pornography. <br />
                        We do not own, produce or host the videos displayed on this website. <br />
                        All videos are hosted by 3rd party websites. We have no control over the content of these
                        websites. <br />
                        We take no responsibility for the content on any website which we link to, please use your
                        own discretion while surfing the links.
                    </p>
                    <p class="text-bold text-black">&copy; 2022 <span class="domain">xxxbulk.com</span></p>
                </div>
            </div>

            <div class="footer-item">
                <div class="footer-right">

                    <a class="footer-logo" href="/ " title="xxxbulk">xxxbulk</a>

                    <ul class="footer-menu">
                        <li><a [routerLink]="['/legal/compliance']" title="" class="button-std">Section 2257 Compliance Statement</a></li>
                    </ul>

                </div>
            </div>

        </div>
    </div>
</div>
<a href="#" class="button-to-top jsButtonToTop"><i class="icon icon-arrow-up"></i></a>
<div class="age" data-nosnippet>
    <div class="age__content">
        <div class="age__header">
            <img class="age__logo" src="/assets/images/logo.png"
                alt="xxxbulk.com">
            <i class="age__age">18+</i>
        </div>
        <div class="age__text"></div>
        <span class="age__btn" id="age-accept"></span>
    </div>
</div>